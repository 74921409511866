





























































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { webhook } from '@/utils/contanst'

@Component({
  name: 'Webhook',
})
export default class Webhook extends VueBase {
  private subRules = {
    url: [{ required: true, message: '请输入服务地址', trigger: 'blur' }],
    push_type: [{ required: true, message: '请选择推送类型', trigger: 'blur' }],
  }

  // 项目概要   应用漏洞   组件漏洞
  private options: any = [
    // { label: '项目概要', value: 'project_summary' },
    // { label: '应用漏洞', value: 'app_vuln' },
    // { label: '组件漏洞', value: 'sca_vuln' },
    // { label: '项目状态', value: 'project_status' },
    { label: '应用漏洞', value: 'app_vuln' },
  ]

  private subForm: any = { url: '', headers: [], push_type: ['app_vuln'] }
  private headers: any = []
  private type = webhook
  private hasData = false
  private addHeader() {
    this.headers.push({
      key: '',
      vale: '',
    })
  }

  private delHeader(key: number) {
    this.headers.splice(key, 1)
  }

  async submit() {
    const subForm: any = this.$refs.subForm
    subForm.validate(async (valid: any) => {
      if (valid) {
        let obj = {}
        this.headers.forEach((item: any) => {
          obj[item.key] = item.value
        })

        this.subForm.headers = obj
        const res = await this.services.vuln.inteConfig(this.subForm, this.type)
        if (res.status === 201) {
          this.$message.success(res.msg)
          this.$router.go(-1)
          return
        }
        this.$message.error(res.msg)
      }
    })
  }

  async deleteInte() {
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: 'cancelButtonClass',
      confirmButtonClass: 'delete-btn',
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除当前配置?
        </div>
        <div class="tip">
          删除后不可恢复，请谨慎操作
        </div>
      `,
    }).then(async () => {
      const res = await this.services.vuln.deleteConfig(this.type)
      if (res.status === 201) {
        this.$message.success(res.msg)
        this.$router.go(-1)
      }
    })
  }

  async created() {
    const res = await this.services.vuln.getConfig(this.type)
    if (res.status === 201) {
      if (res.data) {
        this.subForm = {
          ...res.data.notify_meta_data,
        }
        let headerObj: any
        if (res.data.notify_meta_data.headers) {
          headerObj = res.data.notify_meta_data.headers
        }
        this.headers = []
        for (let key in headerObj) {
          this.headers.push({
            key: key,
            value: headerObj[key],
          })
        }
        this.hasData = true
      }
    }
  }

  private mLoading = false
  private mData: any = []
  async getLogSummary() {
    this.mLoading = true
    const { data, status } = await this.services.vuln.getLogSummary()
    this.mLoading = false
    if (status !== 201) return
    this.mData = this.formatData(data || [])
  }

  formatData(data: any) {
    return data.map((item: any) => {
      let temp: any = {}
      let hs = item.lastIndexOf('https://')
      let h = item.lastIndexOf('http://')
      if (h > hs) {
        temp.info = item.slice(0, h)
        temp.url = item.slice(h)
      } else {
        temp.info = item.slice(0, hs)
        temp.url = item.slice(hs)
      }
      return temp
    })
  }
  handleLink(url: any) {
    window.open(url)
  }
}
